<template>
  <div style="background: radial-gradient(circle, #7b0e7f 0%, #431044 90%); min-height: 100vh; height: auto; background-size: cover; padding-bottom: 15rem; background-attachment: fixed;">
    <!-- 431044 -->
    <Sticky>
        <div> 
        <NavBar />
        <div class="col-lg-12 px-2 col-12 mx-auto d-flex main-image-wrapper">
              <b-carousel
                  id="carousel-1"
                  :interval="3000"
                  controls
                  fade
                  style="width: 100%; height: 100%;"
              >
                  <b-carousel-slide
                      img-src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/1.png"
                  ></b-carousel-slide>
                  <b-carousel-slide
                      img-src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/2.png"
                  ></b-carousel-slide>
                  <!-- <b-carousel-slide
                      img-src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/icons/banner3.jpg"
                  ></b-carousel-slide>
                  <b-carousel-slide
                      img-src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/icons/banner4.jpg"
                  ></b-carousel-slide>
                  <b-carousel-slide
                      img-src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/icons/banner5.jpg"
                  ></b-carousel-slide>
                  <b-carousel-slide
                      img-src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/icons/banner6.jpg"
                  ></b-carousel-slide> -->
              </b-carousel>
          </div>

        <div class="d-flex px-0 px-3 mx-auto my-2" style="width: 97%;z-index: 10; padding-top: 2px; padding-bottom: 2px; background: #282A31 !important; border-radius: 5px;">
          <i class="fas fa-volume-up my-auto text-white pr-3" style="font-size: 14px; font-weight: 600;"></i>
          <NoticeBar
            class="mx-0 px-0 mx-auto notice-bar col-11"
            scrollable
            speed="40"
            :text="websiteInfo.noticeText"
            v-if="!threedData.embedded_link && !threedData.isLive"
          />
          <!-- websiteInfo[0]?.noticeText  -->
        </div>

        <!-- <div class="my-3 text-center py-2" style="background: #F4DC83; font-weight: 900; color: black;">
          TOP GAMES
        </div> -->
      </div>
    </Sticky>
    

    <div class="d-flex justify-content-center" style="overflow-x: scroll;">
      <!-- <ul class="nav nav-pills"> -->
        <!-- <div class="nav-item d-flex mt-2">
          <div class="side-nav-icon d-flex flex-column p-2" :class="$store.state.sideNav === 'hot' ? 'active': ''" style="padding-top: 0.6rem !important; width: 90%; height: 6rem; border-radius: 10px;" @click="changeSideNav('hot');">
            <img v-show="$store.state.sideNav !== 'hot'" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/dae6345e-9f8a-471a-a361-ce0d318af400/public" class="mx-auto p-0 m-0 pr-1" style="cursor: pointer; width: 2rem;">
            <img v-show="$store.state.sideNav === 'hot'" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/dae6345e-9f8a-471a-a361-ce0d318af400/public" class="mx-auto p-0 m-0 pr-1" style="cursor: pointer; width: 2rem;">
            <span class="mt-1 mx-auto side-nav-icon-text" :class="$store.state.sideNav === 'hot' ? 'active': ''">{{$t('home.hot')}}</span>
          </div>
        </div> -->
        <div class="nav-item mx-auto">
          <div class="side-nav-icon d-flex flex-column mx-auto" :class="$store.state.sideNav === 'slot' ? 'active': ''" style="padding-top: 0.6rem !important; width: 90%; height: 6rem; border-radius: 10px;" @click="changeSideNav('slot');">
            <!-- <img v-show="$store.state.sideNav !== 'slot'" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/75b1ab42-51bc-408a-56cd-85ec16303d00/public" class="mx-auto p-0 ml-2" style="cursor: pointer; width: 3rem;"> -->
            <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/hulk333/general/icon_g_slot.webp" class="mx-auto p-0 ml-2 pulse" style="cursor: pointer; width: 3rem;">
            <span class="mx-auto side-nav-icon-text" :class="$store.state.sideNav === 'slot' ? 'active': ''">{{$t('home.slot')}}</span>
          </div>
        </div>
        <div class="nav-item mx-auto">
          <div class="side-nav-icon d-flex flex-column pt-0 mx-auto" :class="$store.state.sideNav === 'card' ? 'active': ''" style="padding-top: 0.8rem !important; width: 90%; height: 6rem; border-radius: 10px;" @click="changeSideNav('card');">
            <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/hulk333/general/icon_g_casino.webp" class="mx-auto p-0 m-0 pulse" style="cursor: pointer; width: 3rem;">
            <!-- <img v-show="$store.state.sideNav === 'card'" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/0c75648c-b244-4972-a288-6e11ca52f100/public" class="mx-auto p-0 m-0" style="cursor: pointer; width: 3rem;"> -->
            <span class="mx-auto side-nav-icon-text" :class="$store.state.sideNav === 'card' ? 'active': ''">{{$t('home.card')}}</span>
          </div>
        </div>
        <div class="nav-item mx-auto">
          <div class="side-nav-icon d-flex flex-column mx-auto" :class="$store.state.sideNav === 'fishing' ? 'active': ''" style="padding-top: 0.6rem !important;width: 90%; height: 6rem; border-radius: 10px;" @click="changeSideNav('fishing');">
            <!-- <img v-show="$store.state.sideNav !== 'fishing'" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/1c9d8b71-8e10-4dbc-a3f4-6eb1b6631b00/public" class="mx-auto p-0 m-0" style="cursor: pointer; width: 3rem;"> -->
            <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/hulk333/general/icon_g_fish.webp" class="mx-auto p-0 m-0 pulse" style="cursor: pointer; width: 3rem;">
            <span class="mt-1 mx-auto side-nav-icon-text" :class="$store.state.sideNav === 'fishing' ? 'active': ''">{{$t('home.fishing')}}</span>
          </div>
        </div>
        <div class="nav-item mx-auto">
          <div class="side-nav-icon d-flex flex-column pt-0 mx-auto" :class="$store.state.sideNav === 'casino' ? 'active': ''" style="padding-top: 0.8rem !important; width: 90%; height: 6rem; border-radius: 10px;" @click="changeSideNav('casino');">
            <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/hulk333/general/icon_g_hilo.webp" class="mx-auto p-0 m-0 pulse" style="cursor: pointer; width: 3rem;">
            <span class="mx-auto side-nav-icon-text" :class="$store.state.sideNav === 'casino' ? 'active': ''">{{$t('home.casino')}}</span>
          </div>
        </div>
        <div class="nav-item mx-auto">
          <div class="side-nav-icon d-flex flex-column pt-0 mx-auto" :class="$store.state.sideNav === 'football' ? 'active': ''" style="padding-top: 0.8rem !important; width: 90%; height: 6rem; border-radius: 10px;" @click="changeSideNav('football');">
            <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/hulk333/general/icon_g_sport.webp" class="mx-auto p-0 m-0 pulse" style="cursor: pointer; width: 3rem;">
            <span class="mx-auto side-nav-icon-text" :class="$store.state.sideNav === 'football' ? 'active': ''">{{$t('home.football')}}</span>
          </div>
        </div>
      <!-- </ul> -->
    </div>
    <div class="cards-container-mobile">
      <div class="container-fluid justify-content-end px-0 d-flex pt-0" style="position: relative;">
        <!-- <div class="col-12"> -->

						<div class="index_rightbar mr-0 pl-0 col-12 pl-2 pr-1" id="index_rightbar" style="height: 90vh; overflow-y: scroll; position: absolute;">
              <div :style="($store.state.sideNav === 'slot' || $store.state.sideNav === 'hot') ? 'height: 110vh' : 'height: 50vh'">
                <div class="tab-content game  px-2" style="min-height: 50vh; border-radius: 20px; height: auto; padding-bottom: 30rem;">

                  <div class="mx-1 mt-2" :class="$store.state.sideNav === 'hot' ? 'active' : ''" v-show="$store.state.sideNav === 'hot'">
                    <div >
                      <!-- <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/hulk333/jackpot_bg.jpg" alt="" style="width: 100%; height: auto; border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom-right-radius: 5px; border-bottom-left-radius: 5px;"> -->
                      <div class="col-12 p-0 py-3">
                        <div class="d-flex">
                          <div>
                            <h4 class=" text-nowrap" style="color: #fff;">{{$t('home.popular_slot_games')}}</h4>
                          </div>
                          <div class="my-auto ml-3 pb-2" style="width: 60%; border-top: 1px solid #2B2C2E; height: 0px;"></div>
                        </div>
                       
                         <div class="d-flex flex-wrap">
                          <!-- <div class="col-6 my-2" v-for="slotGame in hotGames['slotGames']" :key="slotGame.id">
                            <div class="pb-1">
                              <img style="width: 25px; position: absolute; right: 10px; top: -15px;" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/dae6345e-9f8a-471a-a361-ce0d318af400/public" alt="">
                              <img
                                    :src="imageLinkGenerate(slotGame.gameID, slotGame.provider)"
                                    alt=""
                                    @click="(slotGame.provider === 'PGSoft' || slotGame.provider === 'FaChai') ? showAlertBox(slotGame) : gameInit(slotGame)"
                                    class="pl-1 pt-1 pr-1"
                                    style="width: 100%; height: 100%; border-radius: 10px; min-height: 100px;"
                                />
                                {{ slotGame.name }}
                            </div>
                          </div> -->
                         </div>
                      </div>

                      <div class="col-12 p-0 py-1">
                        <div class="d-flex">
                          <div>
                            <h4 class="text-nowrap" style="color: #fff;">{{$t('home.popular_fishing_games')}}</h4>
                          </div>
                          <div class="my-auto ml-3 pb-2" style="width: 53%; border-top: 1px solid #2B2C2E; height: 0px;"></div>
                        </div>
                       
                         <div class="d-flex flex-wrap">
                            <!-- <div class="col-6 my-2" v-for="slotGame in hotGames['fishingGames']" :key="slotGame.id">
                              <div class="pb-1">
                                <img style="width: 25px; position: absolute; right: 10px; top: -15px;" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/dae6345e-9f8a-471a-a361-ce0d318af400/public" alt="">
                                <img
                                      :src="imageLinkGenerate(slotGame.gameID, slotGame.provider)"
                                      alt=""
                                      @click="(slotGame.provider === 'PGSoft' || slotGame.provider === 'FaChai') ? showAlertBox(slotGame) : gameInit(slotGame)"
                                      class="pl-1 pt-1 pr-1"
                                      style="width: 100%; height: 100%; border-radius: 10px; min-height: 100px;"
                                  />
                              </div>
                            </div> -->
                         </div>
                      </div>


                      <div class="col-12 p-0 py-1">
                        <div class="d-flex">
                          <div>
                            <h4 class="text-nowrap" style="color: #fff;">{{$t('home.popular_arcade_games')}}</h4>
                          </div>
                          <div class="my-auto ml-3 pb-2" style="width: 53%; border-top: 1px solid #2B2C2E; height: 0px;"></div>
                        </div>
                       
                         <div class="d-flex flex-wrap">
                            <!-- <div class="col-6 my-2" v-for="slotGame in hotGames['arcadeGames']" :key="slotGame.id">
                              <div class="pb-1">
                                <img style="width: 25px; position: absolute; right: 10px; top: -15px;" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/dae6345e-9f8a-471a-a361-ce0d318af400/public" alt="">
                                <img
                                    :src="imageLinkGenerate(slotGame.gameID, slotGame.provider)"
                                    alt=""
                                    @click="(slotGame.provider === 'PGSoft' || slotGame.provider === 'FaChai') ? showAlertBox(slotGame) : gameInit(slotGame)"
                                    class="pl-1 pt-1 pr-1"
                                    style="width: 100%; height: 100%; border-radius: 10px; min-height: 100px;"
                                  />
                              </div>
                            </div> -->
                         </div>
                      </div>
                    </div>
                  </div>

                  

                  <!-- <div class="mx-1" :class="this.$store.state.sideNav === 'vs' ? 'active' : ''" v-show="this.$store.state.sideNav === 'vs'">
                    <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/723ca88a-6cf0-406b-d7b3-a93d023f6f00/public" alt="slot" style="cursor: pointer; width: 103%; margin-left: -0.4rem; height: auto;" @click="gameInit('CockFight')">
                  </div> -->

                  <div :class="$store.state.sideNav === 'skm' ? 'active' : ''" v-if="$store.state.sideNav === 'skm'">
                    <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%9C%E1%80%B0%E1%80%9E%E1%80%85%E1%80%BA.png" alt="skm" style="cursor: pointer; width: 48%; height: auto;" @click="fetchShanKoMeeData(0)">
                    <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%82%E1%80%BB%E1%80%B0%E1%80%94%E1%80%AE%E1%80%9A%E1%80%AC.png" alt="skm" style="cursor: pointer; width: 48%; height: auto;" @click="fetchShanKoMeeData(1)">
                    <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%85%E1%80%AE%E1%80%94%E1%80%AE%E1%80%9A%E1%80%AC.png" alt="skm" style="cursor: pointer; width: 48%; height: auto;" @click="fetchShanKoMeeData(2)">
                    <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%98%E1%80%B1%E1%80%AC%E1%80%B7%E1%80%85%E1%80%BA.png" alt="skm" style="cursor: pointer; width: 48%; height: auto;" @click="fetchShanKoMeeData(3)">
                    <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%99%E1%80%AE%E1%80%9C%E1%80%AE%E1%80%9A%E1%80%B6%E1%80%94%E1%80%AC.png" alt="skm" style="cursor: pointer; width: 48%; height: auto;" @click="fetchShanKoMeeData(4)">
                    <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%98%E1%80%AE%E1%80%9C%E1%80%AE%E1%80%9A%E1%80%B6%E1%80%94%E1%80%AC.png" alt="skm" style="cursor: pointer; width: 48%; height: auto;" @click="fetchShanKoMeeData(5)">
                  </div>

                  <div :class="$store.state.sideNav === 'bgy' ? 'active' : ''" v-if="$store.state.sideNav === 'bgy'">
                    <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%9C%E1%80%B0%E1%80%9E%E1%80%85%E1%80%BA.png" alt="bgy" style="cursor: pointer; width: 48%; height: auto;" @click="fetchBugyee(0)">
                    <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%82%E1%80%BB%E1%80%B0%E1%80%94%E1%80%AE%E1%80%9A%E1%80%AC.png" alt="bgy" style="cursor: pointer; width: 48%; height: auto;" @click="fetchBugyee(1)">
                    <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%85%E1%80%AE%E1%80%94%E1%80%AE%E1%80%9A%E1%80%AC.png" alt="bgy" style="cursor: pointer; width: 48%; height: auto;" @click="fetchBugyee(2)">
                    <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%98%E1%80%B1%E1%80%AC%E1%80%B7%E1%80%85%E1%80%BA.png" alt="bgy" style="cursor: pointer; width: 48%; height: auto;" @click="fetchBugyee(3)">
                    <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%99%E1%80%AE%E1%80%9C%E1%80%AE%E1%80%9A%E1%80%B6%E1%80%94%E1%80%AC.png" alt="bgy" style="cursor: pointer; width: 48%; height: auto;" @click="fetchBugyee(4)">
                    <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%98%E1%80%AE%E1%80%9C%E1%80%AE%E1%80%9A%E1%80%B6%E1%80%94%E1%80%AC.png" alt="bgy" style="cursor: pointer; width: 48%; height: auto;" @click="fetchBugyee(5)">
                  </div>

                  <div class="d-flex justify-content-between flex-wrap" :class="$store.state.sideNav === 'card' ? 'active' : ''" v-if="$store.state.sideNav === 'card'">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Shan%20Koe%20Mee.png" alt="card" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="changeSideNav('skm')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Bu%20Gyii.png" alt="card" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="changeSideNav('bgy')">
                  </div>

                  <div class="pb-3 d-flex justify-content-between flex-wrap" :class="$store.state.sideNav === 'fishing' ? 'active' : ''" v-if="$store.state.sideNav === 'fishing'">
                    <!-- <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/space97/Joker%20Fishbanner%20(1).png" alt="skm" class="my-2" style="cursor: pointer; width: 48%; height: auto;"  @click="$router.push('/user/fishing?provider=Joker')"> -->
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Fishing%20-%20JILI.png" alt="skm" class="my-2" style="cursor: pointer; width: 48%; height: auto;"  @click="$router.push('/user/fishing?provider=Jili')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Fishing%20-%20JDB.png" class="my-2" alt="skm" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/fishing?provider=JDB')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Fishing%20-%20Spade%20Gaming.png" alt="skm" class="my-2" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/fishing?provider=Spade')">
                    <!-- <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/space97/KA%20Fish@4x%20(1).png" class="my-2" alt="skm" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/fishing?provider=KA')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/space97/AceWin%20FIsh@4x%20(1).png" alt="skm" class="my-2" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/fishing?provider=AceWin')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/space97/FA%20Fishbanner%20(1).png" alt="skm" class="my-2" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/fishing?provider=FaChai')"> -->
                  </div>

                  <div :class="$store.state.sideNav === 'slot' ? 'active' : ''" v-if="$store.state.sideNav === 'slot'" class="pb-2 mx-0 px-0 d-flex justify-content-between flex-wrap">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Slot%20-%20PP.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/slots?provider=Pragmatic')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Slot%20-%20JILI.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/slots?provider=Jili')">
                    <!-- <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Slot%20-%20PG.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/slots?provider=PGSoft')"> -->
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Slot%20-%20JDB.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/slots?provider=JDB')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Slot%20-%20KA%20Gaming.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/slots?provider=KA')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Slot%20-%20JOKER.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/slots?provider=JOKER')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Slot%20-%20FaChai.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/slots?provider=FaChai')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Slot%20-%205G.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/slots?provider=5G')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Slot%20-%20Playstar.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/slots?provider=Playstar')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Slot%20-%20Spade%20Gaming.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/slots?provider=Spade')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Slot%20-%20Ace%20Win.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="$router.push('/user/slots?provider=AceWin')">

                  </div>

                  <div :class="$store.state.sideNav === 'football' ? 'active' : ''" v-if="$store.state.sideNav === 'football'" class="d-flex justify-content-between flex-wrap">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Body.png" alt="football" class="my-2 px-0 mx-0" style="cursor: pointer; width: 49%; height: auto;" @click="$router.push('/body-fb')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Maung.png" alt="football" class="my-2 px-0 mx-0" style="cursor: pointer; width: 49%; height: auto;" @click="$router.push('/maung-fb')">
                    <!-- <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/hulk333/general/hulk_live.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 49%; height: auto;" @click="$router.push('/Live')"> -->
                  </div>

                  <div :class="$store.state.sideNav === 'casino' ? 'active' : ''" v-if="$store.state.sideNav === 'casino'" class="d-flex justify-content-between flex-wrap">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Live%20Casino%20-%20PP.png" alt="casino" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="gameInitCasino('PP Live')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Live%20Casino%20-%20Sexy.png" alt="casino" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="gameInitCasino('Sexy')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Live%20Casino%20-%20WM.png" alt="casino" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="gameInitCasino('WM')">
                    <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/Live%20Casino%20-%20Big%20Gaming.png" alt="skm" class="my-2 px-0 mx-0" style="cursor: pointer; width: 48%; height: auto;" @click="gameInitCasino('BG')">
                  </div>

                  <div :class="$store.state.sideNav === 'download' ? 'active' : ''" v-if="$store.state.sideNav === 'download'">
                    <div class="gwrap_1 pb-3">
                      <h4 class="text-center text-white mt-3">{{$t('home.you_download')}} <br> {{$t('home.on_any_platform')}}</h4>
                      <a href="https://backapi.space97.vip/api/app/download/android" download="melonzeed.apk">
                        <img src="https://cdn.gold549.com/build/assets/img/brand/android-download.png" alt="slot" style="cursor: pointer; width: 50%; height: auto;">
                      </a>
                      <a href="https://backapi.space97.vip/api/app/download/ios" download="melonzeed.mobileconfig">
                        <img src="https://cdn.gold549.com/build/assets/img/brand/ios-download.png" alt="slot" style="cursor: pointer; width: 50%; height: auto; margin-top: 0.1rem;">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
						</div>
					<!-- </div> -->
      </div>
    </div>

    <van-overlay :show="isLoading">
        <div class="wrapper" @click.stop>
          <van-loading type="spinner" color="#ffffff" class="mx-auto"/>
        </div>
    </van-overlay>

      <!-- <LiveChatWidget license="18638211" v-if="testing"/> -->

      <div class="col-4">
        <div
            class="modal fade"
            id="limit"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-form"
            @click="hideLimit()"
        >
            <div
                class="modal-dialog modal- modal-dialog-centered"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-body my-5 mx-3 text-center">
                      <span class="text-danger text-lg">
                        <b>အထူးသတိပြုရန်။</b> <br><br>
                      </span>
                    <span>{{limitText}}</span>
                    <br><br> <span class="text-dark text-sm">
                        <button class="btn btn-danger" @click="gameInit(desiredGame)">
                        {{
                            $store.state.language === "en"
                                ? "Play The Game"
                                : "ဂိမ်းဆော့မည်။"
                        }}
                        </button>
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  </div>
</template>
<script>
import NavBar from '@/layouts/NavBar.vue';
import { NoticeBar, Dialog, Toast, Sticky } from 'vant';
import { mapActions } from 'vuex';
import { LiveChatWidget } from '@livechat/widget-vue';

export default {
  components: { NoticeBar, Sticky, LiveChatWidget },
  data() {
    return {
      testing: true,
      isLoading: false,
      twoDdata: {},
      twoDHistories: {},
      threeDHistories: {},
      threedData: {},
      websiteInfo: {
        home_banners: {},
        noticeText: 'This is a notice',
      },
      isLogged: false,
      hotGames: [],
      limitText: '',
      desiredGame: null,
    };
  },
  destroyed() {
    this.testing = false;
  },
  methods: {
    async gameInitCasino(game) {
    try {
      this.isLoading = true;
      const authUser = await this.fetchUser();
      if (!authUser) {
        this.$router.push('/auth/login');
      }
      
      const res = await axios.get('/games/url', {
        params: {
          provider: game
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      
      // window.open(res.data.data.gameUrl, "_self");
      // this.$store.commit('setIframeURL', res.data.data.gameUrl);
      // this.$store.commit('setIsCardGame', false);
      // this.$router.push('/iframeURL');
      window.location.assign(res.data.data.gameUrl)
      
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      // this.$router.go();
    }
  },
    hideLimit() {
      $('#limit').modal('hide');
    },
    showAlertBox(game) {
      if(game.provider === 'PGSoft') this.limitText = 'PG Soft တွင် 1K unit သည် 1,000 ကျပ်နှင့်ညီမျပါသည်။';
      else if(game.provider === 'FaChai') this.limitText = 'FaChai Provider တွင် 1 unit သည် 100 ကျပ်နှင့်ညီမျပါသည်။';
      // else this.limitText = '5G Provider တွင် 1K unit သည် 100 ကျပ်နှင့်ညီမျပါသည်။';
      $('#limit').modal('show');
      this.desiredGame = game;
    },

    async gameInit(game) {
      try {
        this.$store.commit('setLoading', true);
        const authUser = await this.fetchUser();
        if (!authUser) {
          this.$router.push('/auth/login');
        }
        
        const res = await axios.get('/games/url', {
          params: {
            gameID: game.gameID,
            provider: game.provider
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        // this.$store.commit('setIframeURL', res.data.data.gameUrl);
        // this.$router.push('/iframeURL');
        if(game.provider === 'PGSoft'){
          const newPage = window.open('', '_self');

          // Write HTML content into the new window
          if (newPage) {
            newPage.document.open();
            newPage.document.write(res.data.data.gameUrl);
            newPage.document.close();
          }
        } else {
          window.open(res.data.data.gameUrl, "_self");
        }

        this.$store.commit('setLoading', false);
      } catch (error) {
        this.$store.commit('setLoading', false);
      }
    },
    
    imageLinkGenerate(gameId, type) {
    if (type === 'Jili') {
      return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/jili/GAMEID_${gameId}_EN.png`;
    } if (type === 'Spade') {
      return `https://merchantapi.silverkirin88.com/thumbnail/en_US/${gameId}.jpg`;
    } if (type === 'Fastspin') {
      return `http://api-egame-staging.fsuat.com/thumbnail/en_US/${gameId}.jpg`;
    } if (type === 'Playstar') {
      return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/playstar/${gameId}.png`;
    } if (type === 'FaChai') {
      return `https://agent-icon.fcg1688.net/icon/${gameId}_200x200_en.png`;
    } if (type === 'PGSoft') {
      return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/pgsoft/pgsoft_${gameId}.png`;
    } if (type === 'JOKER') {
      return `https://img.qiangmingbao.net/gameimages/landscape/${gameId}.png`;
    } if (type === '5G') {
      return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/5g/${gameId}.png`;
    } if (type === 'KA') {
      return `https://rmpiconcdn.kaga88.com/kaga/gameIcon?game=${gameId}&lang=en&type=circular_framed`;
    } if (type === 'AceWin') {
      return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/acewin/${gameId}.png`;
    } if (type === 'JDB') {
        return `https://dlntdk2it99.anhuidingyue.com/jdb-assetsv3/games/${gameId}/${gameId}_en.png`;
      }

    // return `https://api-2133.ppgames.net/game_pic/rec/325/${gameId}.png`;
    return `https://space-tech.sgp1.cdn.digitaloceanspaces.com/pp/${gameId}.png`;
  },
    scrollToElement() {
      const container = this.$el.querySelector('.index_rightbar');
      // Scroll to the target element within the container
      if (container) {
        container.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    async fetchBetGame(game = null, link = null) {
      try {
        this.isLoading = true;
        
        if(game){
            this.gameName = game
            this.gameLink = link
        }
            const res = await axios.get(`/${this.gameName}-data`, {
            params: {
                    id : this.$store.state.authUser.name,
                    balance : this.$store.state.authUser.amount,
                    info : {
                            nickname : this.$store.state.authUser.user_name,
                            profile : 3
                        }
                    }
            });
            window.location.assign(`https://${link}.vercel.app/?id=${this.$store.state.authUser.name}&passcode=${res.data.passcode}&exit=https%3A%2F%2F${window.location.host}/home`)
            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async fetchBugyee(level = null) {
      try {
        this.isLoading = true;
        const authUser = await this.fetchUser();
        if (!authUser) {
          this.$router.push('/auth/login');
        }
        
        const amount = authUser.amount;
        if(level == 0 && amount < 1000){
            this.limitText = "1,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 0 && amount > 100000){
            this.limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }

        if(level == 1 && amount < 3000){
            this.limitText = "3,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 1 && amount > 300000){
            this.limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }

        if(level == 2 && amount < 5000){
            this.limitText = "5,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 2 && amount > 500000){
            this.limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        
        if(level == 3 && amount < 10000){
            this.limitText = "10,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 4 && amount < 30000){
            this.limitText = "30,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 5 && amount < 50000){
            this.limitText = "50,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }

            const res = await axios.get('/bugyee-data', {
            params: {
                    id : this.$store.state.authUser.name,
                    level,
                    balance : this.$store.state.authUser.amount,
                    info : {
                            nickname : this.$store.state.authUser.user_name,
                            profile : 3
                        }
                    },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            });
            // console.log(res.data)
            window.location.assign(res.data.url)
            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    async fetchShanKoMeeData(level = null) {
      try {
        this.isLoading = true;
        const authUser = await this.fetchUser();
        if (!authUser) {
          this.$router.push('/auth/login');
        }
        
        const amount = authUser.amount;
        if(level == 0 && amount < 1000){
            this.limitText = "1,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 0 && amount > 100000){
            this.limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }

        if(level == 1 && amount < 3000){
            this.limitText = "3,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 1 && amount > 300000){
            this.limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }

        if(level == 2 && amount < 5000){
            this.limitText = "5,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 2 && amount > 500000){
            this.limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        
        if(level == 3 && amount < 10000){
            this.limitText = "10,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 4 && amount < 30000){
            this.limitText = "30,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 5 && amount < 50000){
            this.limitText = "50,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }

        // if(this.nickname && this.nickname !== ''){
            const res = await axios.get('/shankomee-data', {
            params: {
                    id : this.$store.state.authUser.name,
                    level,
                    balance : this.$store.state.authUser.amount,
                    info : {
                            nickname : this.$store.state.authUser.user_name,
                            profile : 3
                        }
                    },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            });
            // console.log(res.data)
            window.location.assign(res.data.url);
            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    // async gameInit(game) {
    //   try {
    //     this.isLoading = true;
    //     const authUser = await this.fetchUser();
    //     if (!authUser) {
    //       this.$router.push('/auth/login');
    //     }
        
    //     const res = await axios.get('/games/url', {
    //       params: {
    //         provider: game
    //       },
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem('token')}`,
    //       },
    //     });
        
    //     window.open(res.data.data.gameUrl, "_self");
    //     // this.$store.commit('setIframeURL', res.data.data.gameUrl);
    //     // this.$store.commit('setIsCardGame', false);
    //     // this.$router.push('/iframeURL');
        
    //     this.isLoading = false;
    //   } catch (error) {
    //     this.isLoading = false;
    //     // this.$router.go();
    //   }
    // },
    changeSideNav(para) {
      // this.sideNav = para;
      this.$store.commit('updateSideNav', para);
      this.scrollToElement()
    },
    ...mapActions(['logoutUser', 'fetchUser']),
    async redirectRoute(path, query = null) {
      try {
        console.log('query', query)
        if(query) {
          this.$router.push({ path, query });
        } else {
          this.$router.push(path);
        }
      } catch (error) {
        console.log(error);
      }
    },

    logout() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to log out?'
            : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          this.$router.push('/auth/login');
          Toast.success(
            this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
          );
        })
        .catch(() => {});
    },
    async redirectRoute(path, query = null) {
      try {
        if (path === 'tip') {
          const text = this.$store.state.language === 'en'
            ? 'This feature is not support yet.'
            : 'ယခုအချိန်တွင် အသုံးပြုလို့မရနိုင်သေးပါ။';
          Dialog.alert({
            message: text,
            confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Okay'
                      : 'Okay',
          }).then(() => {
          // on close
          });
        } else if(query) {
          this.$router.push({ path, query });
        } else {
          this.$router.push(path);
        }
      } catch (error) {
        console.log(error);
      }
    },
    threedDrawDateFormat(date) {
      return moment(date).format('ll');
    },
    async fetchTwoDdata() {
      try {
        const res = await axios.get('/2d/data');
        this.isLive = res.data.data[0].isLive;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchThreedDdata() {
      try {
        const res = await axios.get('/3d/data');
        this.threedData = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchHotGames() {
      try {
        const res = await axios.get('/hot-games');
        this.hotGames = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTwoThreeDHistories() {
      try {
        const twoDres = await axios.get('/twod_history');
        const threeDres = await axios.get('/threed_history');
        this.twoDHistories = twoDres.data.data;
        this.threeDHistories = threeDres.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    // Function to load the Tawt.io chat widget
    // loadTawkChat() {
    //   console.log('here1')
    //   if (!document.getElementById('tawk-io-chat')) {
    //     console.log('here2')
    //     var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        
    //     // Dynamically add the script to load Tawt.io
    //     var s1 = document.createElement("script");
    //     var s0 = document.getElementsByTagName("script")[0];
    //     s1.async = true;
    //     s1.id = 'tawk-io-chat';
    //     s1.src = 'https://embed.tawk.to/66ed95584cbc4814f7dc1919/1i880up29';
    //     s1.charset = 'UTF-8';
    //     s1.setAttribute('crossorigin', '*');
    //     s0.parentNode.insertBefore(s1, s0);

    //     // Apply custom styles when the widget loads
    //     Tawk_API.onLoad = () => {
    //       this.applyCustomStyles();
    //       setInterval(this.applyCustomStyles, 1000);
    //     };
    //   }
    // },
    // Function to apply custom styles to the Tawk.to iframe
    // applyCustomStyles() {
    //   var tawkIframe = document.querySelector('iframe[title="chat widget"]');
    //   if (tawkIframe) {
    //     tawkIframe.style.bottom = '7rem'; // Adjust this as needed
    //     tawkIframe.style.left = '1rem'; 
    //     tawkIframe.style.right = '0rem'; 
    //   }
    // },
    // // Function to remove the Tawt.io script and widget
    // removeTawkChat() {
    //   const tawkScript = document.getElementById('tawk-io-chat');
    //   if (tawkScript) {
    //     tawkScript.remove(); // Remove the script tag
    //   }

    //   // Remove the Tawk.to iframe widget
    //   const tawkIframe = document.querySelector('iframe[title="chat widget"]');
    //   if (tawkIframe) {
    //     tawkIframe.remove();
    //   }
    // }
  },
  async mounted() {
    this.$store.commit('setLoading', true);
    await this.fetchWebsiteData();
    // await this.fetchHotGames();
    this.$store.commit('setLoading', false);
  },
  beforeDestroy() {
    this.removeTawkChat();
  }
};
</script>
<style scoped>
.pulse {
    -webkit-animation-name: pulse;
animation-name: pulse;
-webkit-animation-duration: 1s;
animation-duration: 1s;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
-webkit-animation-iteration-count: infinite;
animation-iteration-count: infinite;
  }
  @-webkit-keyframes pulse {
  0% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  50% {
  -webkit-transform: scale3d(1.05, 1.05, 1.05);
  transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  }
  @keyframes pulse {
  0% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  50% {
  -webkit-transform: scale3d(1.05, 1.05, 1.05);
  transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  } 
  
.side-nav-icon-text {
  /* color: #A80000; */
  font-size: 0.9rem;
  /* color: #382618; */
}

.side-nav-icon {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  font-weight: 600;
  color: #ccc !important;
  background: linear-gradient(180deg,#333,#151515);
  /* box-shadow: rgba(40, 40, 40, 0.4) 0 2px 4px, rgba(40, 40, 40, 0.4) 0 7px 13px -3px, #000000 0 0 0 inset; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.side-nav-icon.active {
  /* background: #A80000; */
  background: linear-gradient(10deg,#CF05D2,#e8a3e9);
  color: black !important;
  /* box-shadow: rgba(40, 40, 40, 0.4) 0 2px 4px, rgba(40, 40, 40, 0.4) 0 7px 13px -3px, #01ff3c 0 -3px 0 inset; */
}
.side-nav-icon-text.active {
  color: black !important;
}

.sidebar {
    position: fixed;
    /* top: 23.5rem; */
    left: 0;
    height: 55vh;
    padding-bottom: 50px;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    overflow-y: auto;
}
.index_rightbar {
    padding: 0px 10px;
    overflow-y: scroll;
    height: 55vh;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.detail-button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #714DC7;
  text-transform: uppercase;
  border-radius: 10px;
  box-sizing: border-box;
  color: #714DC7;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 0.9rem;
  font-weight: 450;
  line-height: normal;
  margin: 0;
  min-height: 15px;
  min-width: 0;
  outline: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.detail-button:disabled {
  pointer-events: none;
}

.detail-button:hover {
  color: #fff;
  background-color: #714DC7;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.detail-button:active {
  box-shadow: none;
  transform: translateY(0);
}

.nav-pills.game-nav {
    padding-right: 5px;
  }
  .nav-pills .nav-link {
    border-radius: 10px !important;
    margin-bottom: 5px;
    /* padding: 10px; */
    font-size: 10px !important;
    text-align: center;
    color: #ffffff;
    /* background: linear-gradient(#aa9ace, #714DC7); */
  }
  .nav-item {
        padding-right: 0;
        margin-bottom: 0;
        width: 12rem;
    }

  .nav-pills .nav-link img {
    width: 18px !important;
    /* margin-right: 10px; */
    padding-bottom: 4px;
  }
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff !important;
        background: linear-gradient(#fff, #8a69d9);
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.tab-content.game a img {
    /* box-shadow: 0px 3px 14px -5px rgba(0, 0, 0, 0.4); */
    border-radius: 21px;
    margin-bottom: 10px;
    width: 100%;
}
.tab-content.game {
    padding-left: 3px;
}
.tab-content>.tab-pane {
    display: none;
    padding-right: 3px;
}
.tab-content>.active {
    display: block;
}


.css-38745a {
    margin: 0px;
    line-height: 1.5;
    font-size: 12px;
    font-family: NotoSansMyanmar;
    font-weight: 500;
}
.css-17h40hp {
    margin: 0px;
    line-height: 1.5;
    font-size: 14px;
    font-family: NotoSansMyanmar;
    font-weight: 700 !important;
}
.card {
  cursor: pointer;
  border-radius: 10px !important;
}
.main-image-wrapper img {
  width: 100%;
}
.video-container {
  height: 500px;
  width: 100%;
}
.notice-bar {
  margin-top: -40px;
  z-index: 1;
  font-size: 20px;
  color: white !important;
  background: transparent;
}
.twod-threed-cards {
  height: 200px;
}
.live-number {
  font-weight: bold;
  font-size: 25px;
}
.live-result {
  font-weight: bolder;
  font-size: 60px;
  animation-name: live-number-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.not-live-text {
  font-weight: bold;
  font-size: 24px;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
}
.card-body {
  padding: 0px !important;
}
.cards-container-mobile {
  display: none;
}
@keyframes live-number-animation {
  from {
    color: white;
  }
  to {
    color: red;
  }
}

/* @media (max-width: 768px) { */
  /* .middle-container {
    padding-top: 0.1rem !important;
  } */
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .video-container {
    height: 300px;
    width: 100%;
  }
  .main-wrapper {
    padding: 7px !important;
  }
  .notice-bar {
    margin-top: 0px;
    height: 25px;
    z-index: 1;
    font-size: 1rem;
    color: white !important;
    background: transparent;
    border-radius: 7px;
  }
  .live-number {
    font-weight: bold;
    font-size: 20px;
  }
  .live-result {
    font-weight: bolder;
    font-size: 37px;
  }

  .cards-container {
    display: none;
  }
  .cards-container-mobile {
    padding: 0px !important;
    margin-top: 5px !important;
    display: flex;
    flex-wrap: wrap;
  }
  .home-cards {
    padding-left: 10px;
    padding-right: 10px;
  }
  .card-body {
    padding: 0px !important;
  }
  .not-live-text {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .account-container-wrapper {
    width: auto;
    height: auto;
    border-radius: 10px;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 10px !important;
    margin-left: 10px; 
    margin-right: 10px;
    color: #000000;
    /* background: linear-gradient(#aa9ace, #714DC7); */
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  .top-wrapper,
  .bottom-wrapper {
    padding: 10px;
    width: 100%;
    display: flex;
  }
  .top-right,
  .bottom-right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: end;
  }
  .top-left,
  .bottom-left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: start;
  }
/* } */
</style>
